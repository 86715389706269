import React from 'react';
import ImageNext from 'atoms/ImageNext';
import Text from 'atoms/Text';
import List from 'atoms/List';
import Button from 'atoms/Button';
import Container from 'atoms/Container';
import { CLR } from 'styles/theme';
import Carousel from 'atoms/Carousel';
import Image from 'next/image';
import IconArrowPrevious from 'atoms/SvgIcons/IconArrowPrevious.svg';
import IconArrowNext from 'atoms/SvgIcons/IconArrowNext.svg';
import { useRouter } from 'next/router';

const DiseaseBanners = ({ heading, description, windowWidth, diseaseBanners, setOpenDiseaseModal }) => {
    const router = useRouter()
    return (
        <Container type='static' className='px-5 my-8 md:my-7 relative'>
            {diseaseBanners?.length && <div className='hidden md:flex justify-between'>
                <button className={`diseaseArrow--prev absolute -left-4 2xl:-left-8 top-[calc(50%_+_10px)] -translate-y-1/2 rounded-full z-10 group`} data-automation='disease-left-arrow'>
                    <IconArrowPrevious className='text-gray-900 w-10' />
                </button>
                <button className={`diseaseArrow--next absolute -right-4 2xl:-right-8 top-[calc(50%_+_10px)] -translate-y-1/2 rounded-full z-10 group`} data-automation='disease-right-arrow'>
                    <IconArrowNext className='!text-gray-900 w-10' />
                </button>
            </div>}

            <Carousel
                autoplayDelay={10000}
                isAutoPlay
                isShowArrow
                isSingleSlide="px-0"
                loop
                isShowPagination={true}
                navigationElement='disease'
                sliderList={diseaseBanners.map((item) => (
                    <div key={item.title} className='relative py-4 px-6 md:px-10'>
                        <div className='absolute top-0 left-0 w-full h-full lg:aspect-[1280/150] md:max-h-[180px] rounded-2xl overflow-hidden'>
                            {windowWidth < 768
                                ? <Image src={process.env.NEXT_PUBLIC_PHP_BUCKET_URL + item?.mobileBanner} alt={heading + 'Banner'} layout='fill' objectFit='cover' width={374} height={250} />
                                : <Image src={process.env.NEXT_PUBLIC_PHP_BUCKET_URL + item?.webBanner} alt={heading + 'Banner'} layout='fill' objectFit='cover' width={1280} height={180} />
                            }
                        </div>

                        <div className={`relative z-1 max-w-[600px] ${windowWidth < 768 ? 'flex flex-col items-center text-center' : ''}`}>
                            <Text tag='h2' className='font-Montserrat text-18-20 md:text-[18px]  mt-2 mb-0 md:max-w-[520px]' fontWeight={700} color={CLR.white.text} >
                                {item?.title}
                            </Text>

                            <Text tag='p' className='font-Montserrat text-14-16 mt-1 mb-2' fontWeight={500} color={CLR.white.text} >
                                {item?.contents}
                            </Text>
                            <div className={`relative z-1 max-w-full ${windowWidth < 768 ? 'flex flex-col items-center text-start' : ''}`}>

                                <List className='pt-2 grid grid-cols-1 xxs:grid-cols-3 text-12-16 leading-5 pl-0 mb-2.5 gap-1' classList='flex items-start gap-0 text-basic-white' iconType='IconTickThick' list={item?.benefits} mb='mb-2.5 md:mb-3' iconClass="w-3 md:w-4-5 text-basic-white pt-0.5" />
                            </div>
                            <Button size='small' className='rounded-lg w-[168px] px-0 mb-2' onClick={() => router.push('/ayurvedic-doctor?specs=Diabetes')}>Consult a Doctor</Button>
                        </div>
                    </div>
                ))} />
        </Container>
    );
};

export default DiseaseBanners;
